import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AuthLogin,
  AuthLoginConfirmation,
  AuthLoginConfirmationError,
  AuthLoginConfirmationSuccess,
  AuthLoginError,
  AuthLoginResendCode,
  AuthLoginResendCodeError,
  AuthLoginResendCodeSuccess,
  AuthLoginSuccess,
  SignUp,
  SignUpConfirmation,
  SignUpConfirmationError,
  SignUpConfirmationSuccess,
  SignUpError,
  SignUpSuccess,
} from './actions';
import { catchError, exhaustMap, map, of, switchMap, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { selectLogin, selectSignUpRequestId } from './selectors';
import { AuthApiService } from '@common/services';

@Injectable()
export class AuthEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly api = inject(AuthApiService);
  private readonly router = inject(Router);

  public readonly login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLogin),
      exhaustMap(({ email, password }) =>
        this.api.login({ email, password }).pipe(
          map(({ waitUntil, requestId }) =>
            AuthLoginSuccess({ waitUntil, requestId }),
          ),
          tap(
            () =>
              void this.router.navigate(['/', 'auth', 'login', 'confirmation']),
          ),
          catchError(({ error }) => of(AuthLoginError({ error }))),
        ),
      ),
    ),
  );

  public readonly loginConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLoginConfirmation),
      exhaustMap(({ code }) =>
        this.store.select(selectLogin).pipe(
          take(1),
          switchMap(({ requestId }) =>
            this.api
              .loginConfirmation({
                code,
                requestId,
              })
              .pipe(
                map(({ token }) => {
                  localStorage.setItem('accessToken', token);
                  void this.router.navigate(['/', 'cabinet']);
                  return AuthLoginConfirmationSuccess();
                }),
                catchError((error) =>
                  of(AuthLoginConfirmationError({ error })),
                ),
              ),
          ),
        ),
      ),
    ),
  );

  public readonly loginResendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLoginResendCode),
      exhaustMap(() =>
        this.store.select(selectLogin).pipe(
          take(1),
          switchMap(({ requestId }) =>
            this.api.resendCode({ requestId }).pipe(
              map(({ waitUntil, requestId }) =>
                AuthLoginResendCodeSuccess({ waitUntil, requestId }),
              ),
              catchError((error) => of(AuthLoginResendCodeError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignUp),
      exhaustMap(({ email, password, name }) =>
        this.api.signUp({ email, password, name }).pipe(
          map(({ requestId, waitUntil }) => {
            void this.router.navigate(['/', 'auth', 'sign-up', 'confirmation']);
            return SignUpSuccess({ requestId, waitUntil });
          }),
          catchError((error) => of(SignUpError({ error }))),
        ),
      ),
    ),
  );

  public readonly signUpConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignUpConfirmation),
      exhaustMap(({ code }) =>
        this.store.select(selectSignUpRequestId).pipe(
          take(1),
          switchMap((requestId: string) =>
            this.api.signUpConfirmation({ requestId, code }).pipe(
              map(() => {
                void this.router.navigate(['/', 'auth', 'login']);
                return SignUpConfirmationSuccess();
              }),
              catchError((error) => of(SignUpConfirmationError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );
}
