import { createSelector } from '@ngrx/store';
import { IAuthLoginInitialState } from '@common/interfaces';
import { HttpErrorResponse } from '@angular/common/http';

export const authLogin = 'authLogin';
export const authSignUp = 'authSignUp';

const authLoginStore = (state: any) => state[authLogin];

export const selectLogin = createSelector(
  authLoginStore,
  (state: IAuthLoginInitialState) => state,
);

export const selectLoginIsPending = createSelector(
  selectLogin,
  ({ isPending }) => isPending,
);
export const selectLoginError = createSelector(
  selectLogin,
  ({ error }) => error,
);

const authSignUpState = (state: any) => state[authSignUp];
export const selectSignUp = createSelector(
  authSignUpState,
  (state: any) => state,
);
export const selectSignUpIsPending = createSelector(
  selectSignUp,
  ({ isPending }: any) => isPending,
);
export const selectSignUpError = createSelector(
  selectSignUp,
  ({ error }: any) => error as HttpErrorResponse | null,
);
export const selectSignUpRequestId = createSelector(
  selectSignUp,
  ({ requestId }: any) => requestId,
);
