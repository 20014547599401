import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const AuthLogin = createAction(
  '[Auth Page] Login',
  props<{ email: string; password: string }>(),
);
export const AuthLoginSuccess = createAction(
  '[Auth Page] Login Success',
  props<{ waitUntil: string; requestId: string }>(),
);
export const AuthLoginError = createAction(
  '[Auth Page] Login Error',
  props<{ error: HttpErrorResponse }>(),
);

export const AuthLoginConfirmation = createAction(
  '[Auth Page] Confirmation',
  props<{ code: string }>(),
);
export const AuthLoginConfirmationSuccess = createAction(
  '[Auth Page] Confirmation Success',
);
export const AuthLoginConfirmationError = createAction(
  '[Auth Page] Confirmation Error',
  props<{ error: HttpErrorResponse }>(),
);

export const AuthLoginResendCode = createAction(
  '[Auth Page] Login Resend Code',
);
export const AuthLoginResendCodeSuccess = createAction(
  '[Auth Page] Login Resend Code Success',
  props<{ waitUntil: string; requestId: string }>(),
);
export const AuthLoginResendCodeError = createAction(
  '[Auth Page] Login Resend Code Error',
  props<{ error: HttpErrorResponse }>(),
);

export const AuthLoginErrorReset = createAction('[Auth Page] Error Reset');
export const AuthLoginReset = createAction('[Auth Page] Reset');

export const SignUp = createAction(
  '[Auth Page] Sign Up',
  props<{
    email: string;
    password: string;
    name: string;
  }>(),
);
export const SignUpSuccess = createAction(
  '[Auth Page] Sign Up Success',
  props<{ requestId: string; waitUntil: string }>(),
);
export const SignUpError = createAction(
  '[Auth Page] Sign Up Error',
  props<{ error: HttpErrorResponse | null }>(),
);
export const SignUpConfirmation = createAction(
  '[Auth Page] Sign Up Confirmation',
  props<{ code: string }>(),
);
export const SignUpConfirmationSuccess = createAction(
  '[Auth Page] Sign Up Confirmation Success',
);
export const SignUpConfirmationError = createAction(
  '[Auth Page] Sign Up Confirmation Error',
  props<{ error: HttpErrorResponse | null }>(),
);
export const SignUpReset = createAction('[Auth Page] Reset');
