import { createReducer, on } from '@ngrx/store';
import { authLogin, authSignUp } from './selectors';
import {
  AuthLogin,
  AuthLoginConfirmation,
  AuthLoginConfirmationError,
  AuthLoginError,
  AuthLoginErrorReset,
  AuthLoginResendCodeSuccess,
  AuthLoginReset,
  AuthLoginSuccess,
  SignUp,
  SignUpConfirmation,
  SignUpConfirmationError,
  SignUpError,
  SignUpSuccess,
} from './actions';
import { IAuthLoginInitialState } from '@common/interfaces';

const authLoginInitialState: IAuthLoginInitialState = {
  email: null,
  password: null,
  isPending: false,
  error: null,
  waitUntil: null,
  requestId: null,
};

export const authLoginReducer = createReducer(
  authLoginInitialState,
  on(AuthLogin, (state, { email, password }) => ({
    ...state,
    email,
    password,
    isPending: true,
    error: null,
  })),
  on(
    AuthLoginSuccess,
    AuthLoginResendCodeSuccess,
    (state, { waitUntil, requestId }) => ({
      ...state,
      isPending: false,
      waitUntil,
      requestId,
    }),
  ),
  on(AuthLoginError, (state, { error }) => ({
    ...state,
    isPending: false,
    error,
  })),
  on(AuthLoginErrorReset, (state) => ({
    ...state,
    error: null,
  })),
  on(AuthLoginConfirmation, (state) => ({
    ...state,
    isPending: true,
    error: null,
  })),
  on(AuthLoginConfirmationError, (state, { error }) => ({
    ...state,
    isPending: false,
    error,
  })),
  on(AuthLoginReset, (_state) => ({
    ...authLoginInitialState,
  })),
);

const authSignUpInitialState: any = {
  isPending: false,
  error: null,
  waitUntil: null,
  requestId: null,
};
export const authSignUpReducer = createReducer(
  authSignUpInitialState,
  on(SignUp, (state) => ({
    ...state,
    isPending: true,
    error: null,
    requestId: null,
    waitUntil: null,
  })),
  on(SignUpSuccess, (state, { requestId, waitUntil }) => ({
    ...state,
    isPending: false,
    requestId,
    waitUntil,
  })),
  on(SignUpConfirmation, (state) => ({
    ...state,
    isPending: true,
    error: null,
  })),
  on(SignUpError, SignUpConfirmationError, (state, { error }) => ({
    ...state,
    error,
  })),
);

export const authPageReducers = {
  [authLogin]: authLoginReducer,
  [authSignUp]: authSignUpReducer,
};
